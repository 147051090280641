/*--------------------------------------------------------------
3. General
----------------------------------------------------------------*/
* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

@media (min-width: 1400px) {
  .container {
    max-width: 1270px;
  }
}

// @media (min-width: 1400px) {
//   .container {
//     max-width: 1140px;
//   }
// }

.st-shape-wrap>.container {
  position: relative;
  z-index: 10;
}

hr {
  opacity: 1;
}

.st-primary-color {
  color: $black1;
}

.st-mp0 {
  list-style: none;
  margin: 0;
  padding: 0;
}

.st-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.st-zoom {
  position: relative;
  overflow: hidden;

  .st-zoom-in {
    transition: all 0.4s ease-in-out;
  }

  &:hover {
    .st-zoom-in {
      transform: scale(1.07);
    }
  }
}

.st-vertical-middle {
  display: flex;
  align-items: center;
  height: 100%;

  .st-vertical-middle-in {
    width: 100%;
  }
}

.st-bg {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.st-content {
  position: relative;
  background-color: $white;
  z-index: 10;
  overflow-x: hidden;
}

.st-sticky-footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

.st-gray-bg {
  background-color: $black5;
}

.st-gray-bg1 {
  background-color: #f9f9f9;
}

.st-gray-bg2 {
  background-color: #f8f8fb;
}

.st-bluis-bg {
  background-color: #eaf9fa;
}

.st-gray-overlay {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: rgba($black5, 0.86);
  }

  .container {
    position: relative;
    z-index: 1;
  }
}

hr {
  margin: 0;
  width: 100%;
  border-color: $black4;
}

.st-parallax-shape-wpra {
  position: relative;
}

.st-parallax-shape.st-style1 {
  position: absolute;
  height: 250px;
  width: 250px;
  border-radius: 50%;
  border: 30px solid $black5;
  right: -60px;
  top: 300px;
}

.st-parallax-shape.st-style2 {
  height: 300px;
  width: 300px;
  position: absolute;
  background-color: $black5;
  transform: rotate(-25deg);
  top: 675px;
  left: -9.5%;
}

.st-hover-layer,
.st-hover-layer1,
.st-hover-layer2,
.st-hover-layer3,
.st-hover-layer4,
.st-hover-transform-children {
  position: relative;
  -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: -webkit-transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1),
    -webkit-transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-transform: perspective(600px) translate3d(0, 0, 0);
  transform: perspective(600px) translate3d(0, 0, 0);
}

.st-fixed-bg {
  background-attachment: fixed;
}

.border-10 {
  border-radius: 10px;
}

.pr-40 {
  padding-right: 40px;
}

.pt-25 {
  padding-top: 25px;
}

.st-google-map iframe {
  width: 100%;
  height: 300px;
  border-radius: 5px;
  display: block;
  border: none;
}

.st-google-map.st-type1 iframe {
  height: 600px;
}

.st-google-map.st-type2 iframe {
  height: 150px;
}

.st-light-blue {
  color: $light-blue;
}

.st-pink {
  color: $pink;
}

.st-blue {
  color: $blue;
}

.st-blue-box {
  color: $blue;
  fill: $blue;
  background-color: rgba($blue, 0.1);
}

.st-purple-box {
  color: $purple;
  fill: $purple;
  background-color: rgba($purple, 0.1);
}

.st-green-box {
  color: $green;
  fill: $green;
  background-color: rgba($green, 0.1);
}

.st-red-box {
  color: $red;
  fill: $red;
  background-color: rgba($red, 0.1);
}

.st-dip-blue-box {
  color: $dip-blue;
  fill: $dip-blue;
  background-color: rgba($dip-blue, 0.1);
}

.st-orange-box {
  color: $orange;
  fill: $orange;
  background-color: rgba($orange, 0.1);
}

.st-gray-box {
  color: $gray;
  fill: $gray;
  background-color: rgba($gray, 0.1);
}

.st-pink-box {
  color: $pink;
  fill: $pink;
  background-color: rgba($pink, 0.1);
}

.st-about-wrap {
  position: relative;

  .st-shape-bg {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    z-index: -1;

    img {
      width: 100%;
      height: 500px;
    }

    &:before {
      content: "";
      position: absolute;
      height: 100px;
      width: 100%;
      background-image: linear-gradient(transparent, $white);
      bottom: 0;
      left: 0;
    }
  }
}

.st-tab {
  display: none;
}

.st-tab.active {
  display: block;
}

.st-link-hover-wrap {
  .st-link-hover {
    position: absolute;
    z-index: 2;
    top: -100px;
    right: 20px;
    width: 50px;
    height: 120px;
    background: $blue;
    border-radius: 0 0 40px 40px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    opacity: 0;
    transition: all 0.3s ease;

    i {
      color: $blue;
      background-color: $white;
      height: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      margin-bottom: 5px;
      opacity: 0;
      transform: scale(0.5) rotate(180deg);
      transition: all 0.3s ease;
    }
  }

  &:hover {
    .st-link-hover {
      opacity: 1;
      top: 0px;

      i {
        opacity: 1;
        transform: scale(1) rotate(0deg);
        transition-delay: 0.15s;
      }
    }
  }
}

.st-shape-wrap {
  position: relative;

  .st-shape1,
  .st-shape2,
  .st-shape3,
  .st-shape6 {
    position: absolute;
  }

  .st-shape1 {
    left: 0;
    top: 0;
  }

  .st-shape2 {
    bottom: 0;
    right: 0;
  }

  .st-shape6 {
    top: 0;
    right: 0;
  }

  .st-shape3 {
    top: 0;
    right: 0;
  }

  .st-shape4 {
    position: absolute;
    height: 150px;
    width: 100%;
    bottom: 0;
    left: 0;

    img {
      height: 100%;
      width: 100%;
    }
  }

  .st-shape5 {
    position: absolute;
    width: 100%;
    height: calc(100% - 40px);

    img {
      height: 100%;
      width: 100%;
    }
  }
}

.st-about-img {
  min-height: 300px;
  height: calc(100% - 30px);
  width: calc(100% - 30px);
  border-radius: 7px;
  background: linear-gradient(90deg,
      rgba(146, 90, 39, 0.2) 0%,
      rgba(207, 148, 72, 0.2) 57%);
  margin-top: 30px;

  .st-about-img-in {
    height: 100%;
    border-radius: inherit;
    position: relative;
    left: 30px;
    top: -30px;
  }
}

.ui-widget.ui-widget-content {
  z-index: 10 !important;
}

.st-doctors-info-title,
.st-working-hour-title {
  font-size: 20px;
  margin-bottom: 15px;
}

.st-doctors-info,
.st-working-hour {
  border: 1px solid #eaeaea;
  border-radius: 5px;
  padding: 20px 20px;
}

.st-working-hour-list {
  li {
    display: flex;
    justify-content: space-between;
    position: relative;

    b {
      font-weight: 400;
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    .st-offday {
      color: $black3;
    }
  }
}

.st-doctors-info-list {
  li {
    position: relative;
    padding-left: 30px;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    i {
      position: absolute;
      left: 0;
      top: 5px;
      color: $black3;
    }
  }
}

.st-doctor-name {
  font-size: 24px;
  margin-bottom: 2px;
}

.st-doctor-designation {
  font-size: 16px;
  line-height: 1.6em;
  font-weight: 400;
  color: $black1;
}

.st-doctor-desc {
  margin-top: 10px;
  font-size: 16px;
  line-height: 1.6em;
  color: $black1;
}

.st-doctor-details-box {
  h2 {
    font-size: 20px;
    margin-bottom: 10px;
  }

  p {
    &:last-child {
      margin-bottom: 0;
    }
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
}

.st-doctors-special {
  font-size: 16px;
  line-height: 1.6em;
  display: flex;
  flex-wrap: wrap;
  font-weight: 500;

  b {
    display: inline-block;
    font-weight: 600;
    color: $blue;
    margin-right: 15px;
    flex: none;
    min-width: 105px;
  }

  li {
    width: 50%;
    margin-bottom: 10px;
    display: flex;
  }

  &.st-color1 {
    b {
      color: $light-blue;
    }
  }

  &.st-color2 {
    b {
      color: $black1;
    }
  }
}

.st-doctor-details-box {
  font-size: 16px;
  line-height: 1.6em;
}

.st-left-full-width {
  height: 100%;
  width: calc(50vw - 8px);
  margin-left: calc(-50vw + 100% + 23px);
  padding-left: 15px;
  padding-right: 15px;
}

.st-right-full-width {
  height: 100%;
  width: calc(50vw - 9px);
  margin-left: -15px;
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
}

.st-box {
  &.st-size1 {
    max-width: 820px;
  }

  &.st-align-right {
    margin-left: auto;
  }
}

.st-align-right.st-space30 {
  margin-right: 30px;
}

@media screen and (max-width: 1199px) {

  .st-parallax-shape.st-style1,
  .st-parallax-shape.st-style2 {
    display: none;
  }

  .st-left-full-width {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    margin-left: 0;
    margin-bottom: 40px;
  }

  .st-right-full-width {
    width: 100%;
    margin-left: 0;
    padding: 0;
    margin-bottom: 40px;
  }

  .st-col-reverse-xl {
    flex-direction: column-reverse;
  }
}

@media screen and (max-width: 991px) {
  .st-content {
    margin: 0 !important;
  }

  .st-vertical-middle {
    display: block;
  }

  .st-google-map iframe {
    height: 400px;
  }

  .st-about-wrap .st-shape-bg {
    display: none;
  }

  .st-doctors-info-right {
    padding-left: 0;
  }
}

@media screen and (max-width: 767px) {
  .st-doctors-special li {
    width: 100%;
  }
}

// Services //
/* ADVERTISERS SERVICE CARD */

.sec-icon {
  position: relative;
  display: inline-block;
  padding: 0;
  margin: 0 auto;
}

.sec-icon::before {
  content: "";
  position: absolute;
  height: 1px;
  left: -70px;
  margin-top: -5.5px;
  top: 60%;
  background: #333333;
  width: 50px;
}

.sec-icon::after {
  content: "";
  position: absolute;
  height: 1px;
  right: -70px;
  margin-top: -5.5px;
  top: 60%;
  background: #333;
  width: 50px;
}

.advertisers-service-sec span {
  color: rgb(255, 23, 131);
}

.advertisers-service-sec .col {
  padding: 1em 1em 1em 1em;
  text-align: center;
}

.advertisers-service-sec .service-card {
  width: 100%;
  height: 100%;
  padding: 2em 1.5em;
  border-radius: 5px;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  transition: 0.5s;
  position: relative;
  z-index: 2;
  overflow: hidden;
  background: #fff;
}

.advertisers-service-sec .service-card::after {
  content: "";
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg,
      rgba(146, 90, 39, 1) 0%,
      rgba(207, 148, 72, 1) 57%);
  position: absolute;
  left: 0%;
  top: -98%;
  z-index: -2;
  transition: all 0.4s cubic-bezier(0.77, -0.04, 0, 0.99);
}

.advertisers-service-sec h3 {
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 600;
  color: #1f194c;
  margin: 1em 0;
  z-index: 3;
}

.advertisers-service-sec p {
  color: #575a7b;
  font-size: 15px;
  line-height: 1.6;
  letter-spacing: 0.03em;
  z-index: 3;
}

.advertisers-service-sec .icon-wrapper {
  background-color: #2c7bfe;
  position: relative;
  margin: auto;
  font-size: 30px;
  height: 2.5em;
  width: 2.5em;
  color: #ffffff;
  border-radius: 50%;
  display: grid;
  place-items: center;
  transition: 0.5s;
  z-index: 3;
}

.advertisers-service-sec .service-card:hover:after {
  top: 0%;
}

.service-card .icon-wrapper {
  background-color: #ffffff;
  color: rgb(255, 23, 131);
}

.advertisers-service-sec .service-card:hover .icon-wrapper {
  color: #0dcaf0;
}

.advertisers-service-sec .service-card:hover h3 {
  transition: 0.5s;
  color: #ffffff;
}

.advertisers-service-sec .service-card:hover p {
  color: #f0f0f0;
}

.pb-40 {
  padding-bottom: 160px;
}

.w-60 {
  width: 55px;
}

.w-96 {
  width: 96px;
}

.flex {
  display: flex;

  @media (max-width: 991px) {
    flex-direction: column;
  }
}

.p-10-5 {
  @media (max-width: 991px) {
    padding: 0 10.5px;
  }
}

.grid-class {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 50px;

  @media (max-width: 991px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .vacancy {
    padding: 40px;
    box-shadow: 0 6px 24px 0 rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.08);
    border-radius: 20px;

    h4 {
      color: $blue;
    }

    a {
      color: $blue;
      text-decoration: underline;
    }
  }
}

.grid-class-4 {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 40px;

  @media (max-width: 991px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .Team {
    text-align: center;

    img {
      border-radius: 5px;
    }

    h4 {
      font-size: 18px;
      padding-top: 10px;
      margin-bottom: 6px;
      color: $blue;
    }

    p {
      font-size: 14px;
    }
  }
}

.black {
  color: black !important;
}

.center {
  text-align: center;
}

.jcenter {
  justify-content: center;
}

.l13 {
  line-height: 1.3 !important;
}

.bg-blue {
  background: linear-gradient(90deg,
      rgb(146, 90, 39) 0%,
      rgb(207, 148, 72) 57%) !important;
}

.btn-flex {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.bg-wht {
  background-color: white !important;
  color: $blue !important;
  box-shadow: 0 6px 24px 0 rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.08);
}

.acenter {
  align-items: center;
}

.w-50px {
  width: 50px;
  height: auto;
}

.st-funfact-title-4 {
  font-size: 19px;
  color: $blue;
  padding-top: 20px;
  font-weight: 600;
}

.margin-g {
  padding: 20px;
}

.vc {
  padding: 40px 30px;
  box-shadow: 0 6px 24px 0 rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h4 {
    color: $blue;
  }

  a {
    color: $blue;
    text-decoration: underline;
  }
}

.flex-col {
  flex-direction: column;
}

.iconbox1 {
  justify-content: center;
  align-items: center;
  margin: 40px;
  gap: 20px;
  padding: 10px 20px;
  border: 1px solid $blue;
  border-radius: 8px;

  .flex {
    p {
      margin: 0;
    }

    h6 {
      color: $blue;
    }
  }
}

.justify {
  text-align: justify;
}

.jbetween {
  justify-content: space-between;
}

.h-full {
  height: 100%;
}

.w-full {
  width: 100% !important;
}

.m-r-30 {
  margin-right: 30px;
}

.l-0 {
  left: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

$gray-200: #edf2f7;
$gray-300: #cbd5e0;
$gray-600: #4a5568;
$white: #ffffff;

// Styles
.section {
  &.section-full-width {
    width: 100%;
  }

  &.bg-backgroundSecondary {
    background-color: #f7f7f7;
  }
}

.container {
  &.container-centered {
    margin-left: auto;
    margin-right: auto;
  }

  &.container-horizontal-padding {
    padding-left: 8%;
    padding-right: 8%;
  }

  &.container-vertical-padding {
    padding-top: 8%;
    padding-bottom: 8%;
  }
}

.section-header {
  &.mainTitle {
    font-size: 2rem;
    font-weight: 600;
  }

  &.description {
    font-size: 1rem;
    color: $gray-600;
  }
}

.grid {
  &.grid-one-col {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  &.grid-two-cols {
    @media (min-width: 768px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }

  &.grid-three-cols {
    @media (min-width: 1024px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }

  &.grid-gap-10 {
    gap: 2.5rem;
  }
}

.card {
  &.card-white {
    background-color: $white;
  }

  &.card-shadow {
    box-shadow: 0;
  }

  &.card-border {
    border-color: $gray-200;
  }

  &.card-rounded {
    border-radius: 0.5rem;
  }

  &.card-border-2 {
    border-width: 2px;
  }
}

.shadows {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.uppercase {
  text-transform: uppercase;
}

.text-center {
  text-align: center;
}

.font-semibold {
  font-weight: 600;
}

.text-primary {
  color: $blue !important;
}

.text-white {
  color: $white;
}

.text-sm {
  font-size: 0.875rem;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-gray-300 {
  border-color: $gray-300;
}

.border-t-lg {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.bg-gray-200 {
  background: linear-gradient(90deg,
      rgba(146, 90, 39, 0.1) 0%,
      rgba(207, 148, 72, 0.1) 57%);
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.bg-primary {
  background: linear-gradient(90deg,
      rgba(146, 90, 39, 1) 0%,
      rgba(207, 148, 72, 1) 57%) !important;
}

.rounded-t-lg {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.list-none {
  list-style: none;
  padding: 0 !important;
  margin: 0 !important;
}

@media only screen and (max-width: 768px) {
  .bg-revert {
    background-position: revert;
  }
}

.w-50 {
  width: 50% !important;

  @media (max-width: 991px) {
    width: 100% !important;
  }
}

.row-reverce {
  @media (max-width: 991px) {
    flex-direction: column-reverse;
  }
}

.dis-none {
  @media (max-width: 991px) {
    display: none !important;
  }
}

.dis-block {
  @media (max-width: 991px) {
    display: block !important;
  }
}

.plus {
  @media (max-width: 991px) {
    color: $blue;
    font-size: 26px;
    font-weight: 600;
    padding: 0 10px;
  }
}

.checkbox-c {
  margin-bottom: 23px;
  margin-top: -15px;
}

.pt-10 {
  padding-top: 15px;
}

.pb-20 {
  padding-bottom: 40px;
}

/* ADVERTISERS SERVICE CARD ENDED */

.w-4 {
  width: 18px;
  padding-left: 2px;
}

.w-10 {
  width: 200px;
}

.ficed {
  position: fixed;
  bottom: 25px;
  left: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 10px;
  z-index: 9999;
}

.ficed2 {
  position: fixed;
  bottom: 25px;
  right: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 9999999999999;
}

.pb-10 {
  padding-bottom: 40px;
}

.z-99999 {
  z-index: 999999999;
  position: relative;
}

.grid-container {
  width: 90%;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  /* 1 column by default */
  gap: 1.5rem;
  /* gap equivalent to Tailwind's gap-6 (24px) */
  padding-top: 20px;
  padding-bottom: 100px;
}

.h-750px {
  height: 360px;
}

.left {
  left: 0px !important;
  top: 0px !important;
}

/* Media query for medium screens and above */
@media (min-width: 768px) {
  .grid-container {
    width: 50%;
    padding-top: 40px;
    padding-bottom: 80px;
    grid-template-columns: repeat(2,
        1fr);
    /* 2 columns on medium screens and above */
  }

  .h-750px {
    height: 570px;
  }

  .left {
    left: 30px !important;
    top: -30px !important;
  }
}

.whasapp {
  background-color: #25d366;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 35px;
    height: 35px;
    color: white;
  }
}

.pulse {
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }

  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}

/* Modal.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.5s ease;
  visibility: hidden;
  z-index: 9999999999999;
}

.modal-overlay.open {
  opacity: 1;
  visibility: visible;
  animation: fadeIn 0.5s forwards;
}

.modal-overlay.close {
  animation: fadeOut 0.5s forwards;
}

.modal-content {
  background: white;
  border-radius: 12px;
  width: 800px;
  position: relative;
  transform: translateY(-20px);
  animation: slideIn 0.5s forwards;
}

.modal-overlay.open .modal-content {
  transform: translateY(0);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-20px);
  }

  to {
    transform: translateY(0);
  }
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: transparent;
  font-size: 20px;
  cursor: pointer;
  color: #000;
}

.popupcontainer {
  display: flex;
}

.popupimg {
  background-position: center;
  border-radius: 12px 0px 0px 12px;
  width: 50%;
  background-image: url("https://res.cloudinary.com/db1i46uiv/image/upload/v1728935031/2149206254_hadftj.jpg");
}

.popiptext {
  width: 50%;
  padding: 70px 40px;

  h2 {
    color: rgba($blue, 1);
  }
}

@media only screen and (max-width: 800px) {
  .popupimg {
    height: 240px;
    width: 100%;
  }

  .popiptext {
    width: 100%;
    padding: 40px 20px;

  }

  .popupcontainer {
    display: block;
  }

  .close-button {
    color: #fff;
  }

  .popupimg {
    border-radius: 12px 12px 0px 0px;
  }

  .modal-content {
    width: 90%;
  }
}




.Container {
  min-width: 940px;
  background-color: white;
  margin: auto;
  padding: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 80px;

}

.st-site-branding2 img {
  width: 154px;
}

.content {
  margin: auto;
  overflow: auto;
  height: 90vh;
  position: relative;
  padding-top: 30px;
  scrollbar-width: none;
  /* For Firefox */
  -ms-overflow-style: none;
  /* For Internet Explorer and Edge */
}

.content::-webkit-scrollbar {
  display: none;
  /* For Chrome, Safari, and Opera */
}

.Steps {
  background-size: contain;
  width: 30%;
  height: 100%;
  padding-top: 10px;
  border-radius: 1rem;
}

.Step {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 20px 0px;
  gap: 10px;
  align-items: center;
}

.MainSteps {
  display: block;
  padding: 20px 2px;
}

.stepNumber {
  margin-bottom: 4px;
}

.Step .stepNumber {
  svg {
    color: #0000002c;
    font-size: 22px;
  }
}

.Step .active {
  svg {
    color: rgb(146, 90, 39);
  }
}

.actives p {
  color: #000 !important;
}

.actives span {
  color: #0000007a !important;
}

.Step .stepInfo p {
  font-size: 16px;
  margin-bottom: 2px;
  color: #0000002c;
}

.Step .stepInfo span {
  color: #0000002c;
}


.navigation {
  width: 100%;
  margin-top: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;

}

.navigation button {
  padding: 12px 40px;
  font-weight: 500;
  font-size: 16px;
}

.navigation .btn1 {
  background-color: transparent;
  border: none;
  padding: 12px 0;
  color: #9699ab;
}

.navigation .btn2 {
  background: linear-gradient(90deg, rgb(146, 90, 39) 0%, rgb(207, 148, 72) 57%);
  border: none;
  color: white;
  border-radius: 7px;
}

.btnRight {
  -webkit-box-pack: right !important;
  -ms-flex-pack: right !important;
  justify-content: right !important;
}


.info .form {
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 20px 30px;

}

.info .form .fields input {
  width: 100%;
  font-size: 16px;
  padding: 10px;
  border-radius: 5px;
  border: #d6d9e6 1px solid;
  margin-top: 10px;
  outline: none;
  color: #000;
  font-weight: 500;
}

.info .form .fields select {
  width: 100%;
  font-size: 16px;
  padding: 12.3px;
  border-radius: 5px;
  border: #d6d9e6 1px solid;
  margin-top: 10px;
  outline: none;
  color: #000;
  font-weight: 500;
}



.info .form .fields input:focus {
  border-color: rgb(207, 148, 72);
}

.info .form .fields input::-webkit-input-placeholder {
  color: #9699ab;
}

.info .form .fields input:-ms-input-placeholder {
  color: #9699ab;
}

.info .form .fields input::-ms-input-placeholder {
  color: #9699ab;
}

.info .form .fields input::placeholder {
  color: #9699ab;
}

.info .form .fields select:focus {
  border-color: rgb(207, 148, 72);
}

.info .form .fields select::-webkit-input-placeholder {
  color: #9699ab;
}

.info .form .fields select:-ms-input-placeholder {
  color: #9699ab;
}

.info .form .fields select::-ms-input-placeholder {
  color: #9699ab;
}

.info .form .fields select::placeholder {
  color: #9699ab;
}

.info .form .fields .erorr {
  border-color: #c94451;
}

.info .form .fields label {
  color: #02295a;
}

.info .form .fields span {
  color: #c94451;
}

.dflex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
}

.packs {
  margin-top: 40px;
}

.pack {
  margin-bottom: 15px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: #d6d9e6 1px solid;
  padding: 20px;
  border-radius: 8px;
  cursor: pointer;
}

.pack:hover {
  border: #473dff 1px solid !important;
}

.pack .dflex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pack .title {
  padding-left: 20px;
}

.pack .title h3 {
  font-size: 18px;
  color: #174a89;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.pack .title p {
  padding-left: 2px;
  font-weight: 400;
  font-size: 16px;
  padding-top: 5px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.pack span {
  font-size: 18px;
  color: #473dff;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.pack input[type=checkbox] {
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border: #d6d9e6 1px solid;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.pack input[type=checkbox]:after {
  content: url(../../assets/imges/icon-checkmark.svg);
  display: none;
}

.pack input[type=checkbox]:checked {
  background-color: #473dff;
}

.pack input[type=checkbox]:checked::after {
  display: block;
}

.thanks {
  text-align: center;
  padding: 200px 0;
}

.thanks h2 {
  font-size: 32px;
  margin: 15px 0;
  color: #02295a;
  font-weight: 700;
}

.thanks p {
  font-size: 16px;
  color: #9699ab;
  padding: 0 20px;
  line-height: 23px;
}

.payout {
  padding: 20px 25px;
  background-color: #eef5ff;
  border-radius: 10px;
  margin-top: 40px;
}

.payout .title {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-bottom: 25px;
  border-bottom: #d6d9e6 1px solid;
}

.payout .title h3 {
  color: #174a89;
}

.payout .title span {
  color: #174a89;
  font-size: 16px;
  font-weight: 700;
}

.payout .title p {
  text-decoration: underline;
  font-size: 14px;
  margin-top: 10px;
  cursor: pointer;
}

.addFinish {
  padding-top: 20px;
}

.addFinish p {
  font-weight: 400;
}

.addFinish span {
  color: #174a89;
}

.total {
  padding: 20px 25px;
}

.total p {
  font-weight: 400;
  font-size: 15px;
}

.total span {
  color: #473dff;
  font-weight: 700;
  font-size: 20px;
}

.Plan {
  width: 100%;
}

.Plan .switch {
  width: 100%;
  background-color: #eef5ff;
  border-radius: 10px;
  padding: 15px 0;
  text-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.Plan .switch input {
  margin: 0px 20px;
}

.Plan .switch input[type=checkbox] {
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 45px;
  height: 25px;
  border-radius: 30px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #02295a;
  padding: 5px;
}

.Plan .switch input[type=checkbox]:after {
  content: "";
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: white;
}

.Plan .switch input[type=checkbox]:checked {
  background-color: #02295a;
  -webkit-box-pack: right;
  -ms-flex-pack: right;
  justify-content: right;
}

.Plan .switch input[type=checkbox]:checked::after {
  -webkit-box-pack: left;
  -ms-flex-pack: left;
  justify-content: left;
}

.Plan span {
  font-weight: 700;
  color: #9699ab;
}

.Plan .switOn {
  color: #174a89;
}

.plansCrads {
  display: -ms-grid;
  display: grid;
  // -ms-grid-columns: (1fr)[3];
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 40px;
  margin-bottom: 30px;
}

.planClick {
  border: #473dff 1px solid !important;
  background-color: #F8F9FE;
}

.planCard {
  padding: 20px 15px;
  border: #d6d9e6 1px solid;
  border-radius: 10px;
  cursor: pointer;
}

.planCard:hover {
  border: #473dff 1px solid !important;
}

.planCard .title {
  margin-top: 40px;
}

.planCard .title h3 {
  font-size: 18px;
  color: #174a89;
}

.planCard .title p {
  margin-top: 10px;
  font-size: 14px;
}

@media (max-width: 768px) {
  main {
    width: 100%;
    height: auto;
  }

  .Container {
    min-width: 100%;
    height: auto;
    background-color: #eef5ff;
    margin: auto;
    padding: 0px;
    border-radius: 0;
    display: block;

  }

  .MainSteps {
    display: none;
  }

  .Steps {
    background: url("../../assets/imges/bg-sidebar-mobile.svg") no-repeat;
    width: 100%;
    background-size: cover;
    height: 150px;
    padding-top: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 0;
  }

  .Steps .Step {
    margin: 30px 0px;
    display: block;
  }

  .Steps .Step .stepNumber {
    margin-right: 5vw;
    padding: 8px 13px;
  }

  .stepInfo {
    display: none;
  }

  .content {
    border-radius: 10px;
    padding-bottom: 100px;
    margin-top: -110px;
  }

  .info {
    padding-bottom: 100px;
    max-width: 90%;
    margin: auto;
    padding: 5%;
    background-color: white;
    border-radius: 10px;
    -webkit-box-shadow: 0 5px 10px #d6d9e6;
    box-shadow: 0 5px 10px #d6d9e6;
  }

  .info p {
    font-size: 16px;
    line-height: 25px;
    font-weight: 500;
  }

  .info h2 {
    font-size: 28px;
  }

  .info .form {
    margin-top: 20px;
    padding-bottom: 15px;
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 2px;
  }

  .info .form .fields {
    margin-top: 20px;
  }

  .info .form .fields input {
    font-size: 16px;
    padding: 10px 15px;
    margin-top: 5px;
  }

  .navigation {
    width: 100%;
    margin: auto;
    background-color: white;
    padding: 3% 20px;
    bottom: 0px;
    position: fixed;
  }

  .navigation button {
    font-size: 14px;
  }

  .thanks {
    padding: 75px 0;
    max-width: 90%;
    margin: auto;
    background-color: white;
    position: relative;
    margin-top: -0px;
    border-radius: 10px;
    top: auto;
    -webkit-transform: initial;
    transform: initial;
    -webkit-box-shadow: 0 5px 10px #d6d9e6;
    box-shadow: 0 5px 10px #d6d9e6;
  }

  .packs {
    margin-top: 25px;
  }

  .pack {
    padding: 15px;
  }

  .pack span {
    font-size: 14px;
  }

  .pack .title {
    padding-left: 15px;
  }

  .pack .title p {
    font-size: 13px;
    line-height: 15px;
    padding-left: 0;
  }

  .pack .title h3 {
    font-size: 15px;
  }

  .pack input[type=checkbox] {
    height: 20px;
    width: 20px;
  }

  .total {
    padding: 20px 25px 10px 25px;
  }

  .total span {
    font-size: 18px;
  }

  .plansCrads {
    display: block;
  }

  .planCard {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 20px 15px;
    border: #d6d9e6 1px solid;
    border-radius: 10px;
    margin-bottom: 10px;
  }

  .planCard .title {
    margin-top: 0px;
    margin-left: 15px;
  }

  .planCard .title h3 {
    font-size: 18px;
    color: #174a89;
  }

  .planCard .title p {
    margin-top: 0px;
    font-size: 16px;
  }
}

@media (min-width: 1201px) {
  .content {
    width: 100%;
  }
}

.downloadable {
  color: rgb(146, 90, 39);
  font-size: 15px;
  text-decoration: underline;
  margin: 0;
}

.icondownload {
  display: flex;
  gap: 40px;

  img {
    width: 40px;

  }
}

.downloadfilename {
  display: flex;
  padding: 12px;
  background-color: rgba(207, 148, 72, 0.1);
  border-radius: 6px;

  .filename {
    width: 80%;

    p {
      font-size: 15px;
      opacity: 0.8;
      margin: 0;
    }

  }

  .filedownload {

    p {
      font-size: 15px;
      opacity: 0.8;
      margin: 0;
    }
  }
}

.downloadfile {
  display: flex;
  padding: 12px;
  border-bottom: 1px solid gainsboro;
  align-items: center;

  .downfirst {
    width: 80%;
    display: flex;
    gap: 15px;
    align-items: center;
    .pdfimg img {
      width: 24px;
    }
  }
}


.filedownload p{
  margin-bottom: 5px;
}

.downloadfile:hover {
  background-color: rgba(207, 148, 72, 0.07);
}