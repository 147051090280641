/*--------------------------------------------------------------
33. Footer
----------------------------------------------------------------*/
.st-site-footer {
  background-color: $black5;

  &.st-color1 {
    background-color: $blue;

    .st-copyright-wrap {
      background-color: $blue;
    }

    .st-footer-widget-title,
    .st-social-btn.st-style1 li {
      color: white;
    }

    #st-backtotop {
      background-color: $light-blue;
    }

    .st-social-btn.st-style1 li a:hover,
    .st-footer-widget-nav a:hover {
      color: white;
    }
  }

  &.st-color2 {
    background-color: $blue;

    .st-copyright-wrap {
      background-color: $blue;
    }

    .st-footer-widget-title,
    .st-social-btn.st-style1 li {
      color: $pink;
    }

    #st-backtotop {
      background-color: $pink;
    }

    .st-social-btn.st-style1 li a:hover,
    .st-footer-widget-nav a:hover {
      color: $pink;
    }
  }
}

.st-main-footer {
  padding-top: 100px;
  padding-bottom: 60px;
  background: linear-gradient(90deg, rgba(146,90,39,1) 0%, rgba(207,148,72,1) 57%);;
}

.st-social-btn.st-style1 {
  display: flex;
  flex-wrap: wrap;

  li {
    font-size: 22px;
    color: white;
    margin-right: 15px;

    a {
      display: flex;

      &:hover {
        color: white;
        opacity: 0.8;
      }
    }
  }
}

.st-footer-widget {
  margin-bottom: 40px;
}

.st-footer-widget-title {
  font-size: 20px;
  position: relative;
  padding-bottom: 17px;
  margin: 0;
  margin-bottom: 30px;
  margin-top: -4px;
  color: white;
  font-weight: 600;

  &:before {
    content: "";
    position: absolute;
    height: 1px;
    width: 100%;
    max-width: 170px;
    background-color: $black4;
    bottom: 0;
    left: 0;
  }
}
.st-footer-logo {
  width: 150px;
}

.st-footer-widget-nav {
  margin-top: -5px;
  color: white;
  display: flex;
  gap: 45px;

  li {
    position: relative;
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    display: inline-block;
    padding-left: 15px;
    position: relative;

    &:hover {
      color: white;
      opacity: 0.5;
    }
  }

  i {
    position: absolute;
    left: 0;
    top: 6px;
    font-size: 12px;
  }
}

.st-footer-text {
  margin-top: -1px;
  color: white;
}

.st-copyright-wrap {
  background: linear-gradient(90deg, rgba(146,90,39,1) 0%, rgba(207,148,72,1) 57%);
}

.st-copyright-in {
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
  border-top: 1px solid white;
  align-items: center;
  color: white;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: start;
  }

}

.st-copyright-text {
  font-size: 14px;
  line-height: 1.6em;
}

#st-backtotop {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $blue;
  color: rgba($white, 0.8);
  font-size: 18px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: rgba($blue, 0.8);
  }
}

.st-footer-contact-list {
  li {
    display: flex;
    margin-bottom: 15px;
    color: white;

    span {
      margin-right: 8px;
      font-weight: 600;
      min-width: 70px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &.st-with-icon {
    li {
      position: relative;
      padding-left: 30px;

      i,
      svg {
        position: absolute;
        left: 0;
        top: 5px;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .st-sticky-footer {
    position: initial;
  }

  .st-main-footer {
    padding-top: 60px;
    padding-bottom: 20px;
  }
}

.p-r {
  padding-left: 40px;
  @media (max-width: 991px) {
    padding-left: 10.5px;
  }

}

.copyright-right {
  margin: 0;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  gap: 15px;
  div {
    display: flex;
    gap: 20px;
    justify-content: right;
    @media (max-width: 991px) {
      display: none;
        }

    li {
      list-style: none;

      &:hover {
        opacity: 0.5;
      }
    }
  }
}
